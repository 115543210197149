import { QueueDetails } from "./queue";
import { useState, useCallback, useRef, useEffect } from "react";
import { remove as removeDiacritics } from "diacritics";
import { throttleTime } from "rxjs/operators";
import { asyncScheduler } from "rxjs/internal/scheduler/async";

export const reactIsInDevMode = () => {
  return process.env.NODE_ENV === "development";
};

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const sortIgnoreCaseComparator = (a: string, b: string) => {
  a = removeDiacritics(a.toLowerCase());
  b = removeDiacritics(b.toLowerCase());
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

/**
 * Sort list of objects by property
 * @param list list of items
 * @param prop sort property
 * @param comparator comparator function
 */
export const sortByProperty = <T, P>(
  list: ({ [key: string]: any } & T)[],
  prop: string,
  comparator: (a: P, b: P) => number
): T[] => {
  return list.sort((a, b) => comparator(a[prop], b[prop]));
};

export const settingsQueuesListsEquality = (
  a: QueueDetails[],
  b: QueueDetails[]
) => {
  if (a.length !== b.length) {
    return false;
  }
  return !a.find(
    ({ name, id }, idx) => b[idx].name !== name || b[idx].id !== id
  );
};

const debouncedFnMap: { [key: string]: NodeJS.Timeout } = {};
export const debouncedFn = (fn: Function, delay: number, id: string) => {
  if (debouncedFnMap[id]) {
    clearTimeout(debouncedFnMap[id]);
  }
  debouncedFnMap[id] = setTimeout(() => {
    fn();
    delete debouncedFnMap[id];
  }, delay);
  return () => {
    if (debouncedFnMap[id]) {
      clearTimeout(debouncedFnMap[id]);
      delete debouncedFnMap[id];
    }
  };
};

export const compassDebouncePipe = <T>(delay: number) =>
  throttleTime<T>(delay, asyncScheduler, {
    leading: false,
    trailing: true,
  });
