import React from "react";
import { useSelector } from "react-redux";
import RotatingMessage from "src/components/RotatingMessage/RotatingMessage";
import QueueStats from "src/components/QueueStats/QueueStats";
import {
  selectMessages,
  selectQueues,
  selectQueuesSettings,
} from "src/store/settings";
import Base from "./Base";
import styles from "./Dashboard.module.css";
import { isQueueVisible } from "src/utils/queue";

const Dashboard: React.FC = () => {
  const queues = useSelector(selectQueues);
  const queuesSettings = useSelector(
    selectQueuesSettings,
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
  const messages = useSelector(selectMessages);
  return (
    <Base>
      {messages.length ? <RotatingMessage messages={messages} /> : null}
      <div className={styles.dashboard}>
        {queues
          .filter(({ id }) => isQueueVisible(id, queuesSettings))
          .map((queue) => (
            <QueueStats key={queue.id} queueId={queue.id} />
          ))}
      </div>
    </Base>
  );
};

export default Dashboard;
