import React, { useState } from "react";
import Select from "src/components/Select/Select";
import SettingsMessagesForm from "src/components/SettingsMessagesForm/SettingsMessagesForm";
import SettingsNavbar from "src/components/SettingsNavbar/SettingsNavbar";
import SettingsValuesForm from "src/components/SettingsValuesForm/SettingsValuesForm";
import SettingsViewForm from "src/components/SettingsViewForm/SettingsViewForm";
import Base from "./Base";
import styles from "./Settings.module.css";

enum SubPage {
  view = "view",
  values = "values",
  messages = "messages",
}

function Settings() {
  const [subPage, setSubPage] = useState<SubPage>(SubPage.view);
  let $content: JSX.Element | null = null;
  switch (subPage) {
    case SubPage.view:
      $content = <SettingsViewForm />;
      break;
    case SubPage.values:
      $content = <SettingsValuesForm />;
      break;
    case SubPage.messages:
      $content = <SettingsMessagesForm />;
      break;
  }
  return (
    <Base showScrollTopBtn={true}>
      <div className={styles.wrap}>
        <SettingsNavbar />
        <div className={styles.settings}>
          <div className={styles.menu}>
            <Select
              value={subPage}
              items={[
                { value: SubPage.view, text: "View" },
                { value: SubPage.values, text: "Values" },
                { value: SubPage.messages, text: "Messages" },
              ]}
              onSelect={(value) => {
                if (subPage === value) {
                  return;
                }
                setSubPage(value as SubPage);
              }}
            />
          </div>
          <div className={styles.content}>{$content}</div>
        </div>
      </div>
    </Base>
  );
}

export default Settings;
