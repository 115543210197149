import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { usePrevious } from "src/utils";
import { humanizeDuration } from "src/utils/dateTime";
import Card from "../Card/Card";
import styles from "./BasicWidget.module.css";
import DoughnutChart, {
  DoughnutChartProps,
} from "../DoughnutChart/DoughnutChart";

type BasicWidgetProps = {
  label: string;
  value: number;
  icon?: IconDefinition;
  limit?: number;
  highlightLimit?: boolean;
  format?: "time";
  className?: string;
  doughnutChart?: DoughnutChartProps;
  counter?: boolean;
};

const BasicWidget: React.FC<BasicWidgetProps> = ({
  label,
  value,
  icon,
  limit,
  highlightLimit,
  className,
  doughnutChart,
  format,
  counter,
}) => {
  // Used for time counters to update display value every seconds
  const [counterAdditionalValue, setCounterAdditionalValue] =
    useState<number>(0);
  let displayValue: string = value.toString();
  let displayLimit: string | undefined = limit ? limit.toString() : undefined;
  if (format === "time") {
    displayValue = humanizeDuration(value + counterAdditionalValue);
    if (limit) {
      displayLimit = humanizeDuration(limit);
    }
  }
  let limitWarning = false;
  let limitDanger = false;
  if (highlightLimit && limit) {
    limitWarning = value > limit / 2;
    limitDanger = value >= limit;
  }
  let doughnutChartParams: DoughnutChartProps | undefined = doughnutChart;
  if (doughnutChart && !doughnutChart.color) {
    doughnutChartParams = {
      ...doughnutChart,
      color: limitDanger ? "danger" : limitWarning ? "warning" : undefined,
    };
  }
  const previousValue = usePrevious(value);
  useEffect(() => {
    if (!counter || format !== "time") {
      if (counterAdditionalValue) {
        setCounterAdditionalValue(0);
      }
      return;
    }
    if (previousValue !== value && counterAdditionalValue) {
      setCounterAdditionalValue(0);
    }
    const updateInterval = setInterval(() => {
      setCounterAdditionalValue(counterAdditionalValue + 1);
    }, 1000);
    return () => {
      clearInterval(updateInterval);
    };
  }, [value, counter, format, counterAdditionalValue, previousValue]);
  return (
    <Card
      className={`${styles.widget} ${
        limitDanger
          ? styles["widget--danger"]
          : limitWarning
          ? styles["widget--warning"]
          : undefined
      } ${className ? className : ""}`}
    >
      <div className={styles.content}>
        {icon && (
          <div className={styles.icon}>
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
        <div className={styles.info}>
          <div className={styles.label}>{label}</div>
          <div className={styles.valueWrap}>
            <div className={styles.value}>{displayValue}</div>
            {displayLimit ? (
              <div className={styles.limit}>/{displayLimit}</div>
            ) : null}
          </div>
        </div>
        {doughnutChartParams && (
          <div className={styles.chartWrap}>
            <DoughnutChart {...doughnutChartParams} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default BasicWidget;
