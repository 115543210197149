import React from "react";
import styles from "./Loader.module.css";

interface LoaderProps {
  size?: "default" | "large";
  color?: "primary" | "grey";
}

export const Loader: React.FC<LoaderProps> = ({ size, color }) => {
  return (
    <div
      data-color={color}
      data-size={size}
      className={`${styles["loader"]} ball-pulse`}
    >
      {/* 3 empty divs to show 3 dots */}
      <div />
      <div />
      <div />
    </div>
  );
};
