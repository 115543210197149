import { RootState } from "./rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userStorage } from "src/utils/userStorage";
import { QueueDetails } from "src/utils/queue";

const QUEUE_SETTINGS_KEY = "queue-settings";
const WIDGETS_VISIBILITY_KEY = "widgets-visibility-settings";
const MESSAGES_KEY = "messages";

export enum WidgetType {
  agentsNumber = "agentsNumber",
  callersWaiting = "callersWaiting",
  averageWaitingTime = "averageWaitingTime",
  averageWaitingTimeToday = "averageWaitingTimeToday",
  maxWaitingTime = "maxWaitingTime",
  callsToday = "callsToday",
}

const DEFAULT_VISIBILITY_SETTINGS = {
  [WidgetType.agentsNumber]: true,
  [WidgetType.callersWaiting]: true,
  [WidgetType.averageWaitingTime]: true,
  [WidgetType.averageWaitingTimeToday]: true,
  [WidgetType.maxWaitingTime]: true,
  [WidgetType.callsToday]: true,
};

export type QueueSettings = {
  visible: boolean;
  maxCallers?: number;
  slaMaxCallers?: number;
  slaMaxWaitingTime?: number;
  slaAvgWaitingTime?: number;
};

type SettingsState = {
  readonly settingsUpdateInProgress: boolean;
  readonly queues: QueueDetails[];
  readonly queuesSettings: { [key: string]: QueueSettings };
  readonly widgetsVisibilitySettings: {
    [WidgetType.agentsNumber]: boolean;
    [WidgetType.callersWaiting]: boolean;
    [WidgetType.averageWaitingTime]: boolean;
    [WidgetType.averageWaitingTimeToday]: boolean;
    [WidgetType.maxWaitingTime]: boolean;
    [WidgetType.callsToday]: boolean;
  };
  readonly messages: string[];
};

const initialState: SettingsState = {
  settingsUpdateInProgress: false,
  queuesSettings: {},
  queues: [],
  widgetsVisibilitySettings: DEFAULT_VISIBILITY_SETTINGS,
  messages: [],
};

export const updateQueuesSettings = createAsyncThunk(
  "settings/updateQueuesSettings",
  async (settings: { [key: string]: QueueSettings }) => {
    await userStorage.setItem(QUEUE_SETTINGS_KEY, settings);
    return settings;
  }
);

export const updateWidgetsVisibilitySettings = createAsyncThunk(
  "settings/updateWidgetsVisibilitySettings",
  async (settings: SettingsState["widgetsVisibilitySettings"]) => {
    await userStorage.setItem(WIDGETS_VISIBILITY_KEY, settings);
    return settings;
  }
);

export const updateMessages = createAsyncThunk(
  "settings/updateMessages",
  async (messages: SettingsState["messages"]) => {
    await userStorage.setItem(MESSAGES_KEY, messages);
    return messages;
  }
);

export const initializeSettings = createAsyncThunk(
  "settings/initializeQueuesSettings",
  async () => {
    return {
      queuesSettings: (await userStorage.getItem(QUEUE_SETTINGS_KEY)) || {},
      widgetsVisibilitySettings:
        (await userStorage.getItem(WIDGETS_VISIBILITY_KEY)) ||
        DEFAULT_VISIBILITY_SETTINGS,
      messages: (await userStorage.getItem(MESSAGES_KEY)) || [],
    };
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    clearSettings(state) {
      state.queuesSettings = {};
      state.queues = [];
    },
    setQueues(state, { payload: queues }: { payload: QueueDetails[] }) {
      state.queues = queues;
    },
    clearQueues(state) {
      state.queues = [];
    },
  },
  extraReducers: {
    [updateQueuesSettings.pending.type]: (state) => {
      state.settingsUpdateInProgress = true;
    },
    [updateQueuesSettings.fulfilled.type]: (
      state,
      { payload: queuesSettings }
    ) => {
      state.queuesSettings = queuesSettings;
      state.settingsUpdateInProgress = false;
    },
    [updateQueuesSettings.rejected.type]: (state) => {
      state.settingsUpdateInProgress = false;
    },

    [updateWidgetsVisibilitySettings.pending.type]: (state) => {
      state.settingsUpdateInProgress = true;
    },
    [updateWidgetsVisibilitySettings.fulfilled.type]: (
      state,
      { payload: widgetsVisibilitySettings }
    ) => {
      state.widgetsVisibilitySettings = widgetsVisibilitySettings;
      state.settingsUpdateInProgress = false;
    },
    [updateWidgetsVisibilitySettings.rejected.type]: (state) => {
      state.settingsUpdateInProgress = false;
    },

    [updateMessages.pending.type]: (state) => {
      state.settingsUpdateInProgress = true;
    },
    [updateMessages.fulfilled.type]: (state, { payload: messages }) => {
      state.messages = messages;
      state.settingsUpdateInProgress = false;
    },
    [updateMessages.rejected.type]: (state) => {
      state.settingsUpdateInProgress = false;
    },

    [initializeSettings.fulfilled.type]: (
      state,
      { payload: { queuesSettings, widgetsVisibilitySettings, messages } }
    ) => {
      state.queuesSettings = queuesSettings;
      state.widgetsVisibilitySettings = widgetsVisibilitySettings;
      state.messages = messages;
    },
  },
});

export const { clearSettings } = settingsSlice.actions;

export const selectQueuesSettings = ({
  settings: { queuesSettings },
}: RootState) => queuesSettings;

export const selectQueues = ({ settings: { queues } }: RootState) => queues;

export const selectWidgetsVisibilitySettings = ({
  settings: { widgetsVisibilitySettings },
}: RootState) => widgetsVisibilitySettings;

export const selectSettingsUpdateInProgress = ({
  settings: { settingsUpdateInProgress },
}: RootState) => settingsUpdateInProgress;

export const selectMessages = ({ settings: { messages } }: RootState) =>
  messages;

export const { setQueues, clearQueues } = settingsSlice.actions;

export default settingsSlice.reducer;
