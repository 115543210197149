import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Notifications from "./components/Notifications/Notifications";
import Dashboard from "./containers/Dashboard";
import Loading from "./containers/Loading";
import Login from "./containers/Login";
import Settings from "./containers/Settings";
import { authInitialize, selectAuthStatus } from "./store/auth";
import { NavigationPage, selectPage } from "./store/navigation";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authInitialize());
  }, [dispatch]);
  const authStatus = useSelector(
    selectAuthStatus,
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
  const page = useSelector(selectPage);
  let $content: JSX.Element | null = null;
  if (authStatus.initializationInProgress) {
    $content = <Loading />;
  } else if (!authStatus.isAuthenticated) {
    $content = <Login />;
  } else {
    switch (page) {
      case NavigationPage.dashboard:
        $content = <Dashboard />;
        break;
      case NavigationPage.settings:
        $content = <Settings />;
        break;
    }
  }
  return (
    <div className="panel">
      <Notifications />
      {$content}
    </div>
  );
}

export default App;
