import React from "react";
import { useSelector } from "react-redux";
import styles from "./QueueStats.module.css";
import BasicWidget from "../BasicWidget/BasicWidget";
import { faUserCheck } from "@fortawesome/pro-solid-svg-icons/faUserCheck";
import { faUserHeadset } from "@fortawesome/pro-solid-svg-icons/faUserHeadset";
import { faUserShield } from "@fortawesome/pro-solid-svg-icons/faUserShield";
import { faUserClock } from "@fortawesome/pro-solid-svg-icons/faUserClock";
import { faEquals } from "@fortawesome/pro-solid-svg-icons/faEquals";
import {
  selectQueuesSettings,
  selectWidgetsVisibilitySettings,
  WidgetType,
} from "src/store/settings";
import { selectCurrentStatistics, selectTodayStatistics } from "src/store/data";
import { Queue } from "compass.js";
import { compassConnection$ } from "src/store/auth";

const getQueueById = (queueId: Queue["id"]) => {
  return compassConnection$.getValue()?.model.queues[queueId];
};

const QueueStats: React.FC<{ queueId: Queue["id"] }> = ({ queueId }) => {
  const queueSettings = useSelector(selectQueuesSettings)[queueId];
  const widgetsVisibilitySettings = useSelector(
    selectWidgetsVisibilitySettings
  );
  const todayStats = useSelector(
    selectTodayStatistics,
    (a, b) => JSON.stringify(a[queueId]) === JSON.stringify(b[queueId])
  )[queueId];
  const realTimeStats = useSelector(
    selectCurrentStatistics,
    (a, b) => JSON.stringify(a[queueId]) === JSON.stringify(b[queueId])
  )[queueId];
  const queue = getQueueById(queueId);
  if (!queue || !realTimeStats) {
    return null;
  }
  const queueWaitDurations = [
    ...realTimeStats.waitDurations,
    ...(todayStats ? todayStats.waitDurations : []),
  ];
  const avgWtToday = queueWaitDurations.length
    ? Math.round(
        queueWaitDurations.reduce((a, b) => a + b) / queueWaitDurations.length
      )
    : 0;
  const maxWtToday = queueWaitDurations.length
    ? Math.max.apply(null, queueWaitDurations)
    : 0;
  return (
    <section className={styles.queueStats}>
      <div className={styles.title}>{queue.name}</div>
      <div
        className={`${styles.widgets} ${
          widgetsVisibilitySettings[WidgetType.agentsNumber]
            ? styles["widgets--has-total-agents-widget"]
            : ""
        }`}
      >
        <BasicWidget
          label="Status: Available"
          className={styles.widget}
          value={realTimeStats.agentsAvailable}
          icon={faUserCheck}
        />
        <BasicWidget
          label="Status: In call"
          className={styles.widget}
          value={realTimeStats.agentsInCall}
          icon={faUserHeadset}
        />
        <BasicWidget
          label="Status: No queue calls"
          className={styles.widget}
          value={realTimeStats.agentsBusy}
          icon={faUserShield}
        />
        <BasicWidget
          label="Status: No calls"
          className={styles.widget}
          value={realTimeStats.agentsAway}
          icon={faUserClock}
        />
        {widgetsVisibilitySettings[WidgetType.agentsNumber] ? (
          <BasicWidget
            label="Total agents"
            className={styles.widget}
            value={realTimeStats.agentsTotal}
            icon={faEquals}
            highlightLimit={true}
          />
        ) : null}
        {widgetsVisibilitySettings[WidgetType.callersWaiting] ? (
          <BasicWidget
            label="Waiting"
            className={styles.widget}
            value={realTimeStats.callsWaiting}
            limit={queueSettings?.slaMaxCallers}
            highlightLimit={true}
            doughnutChart={
              queueSettings?.maxCallers
                ? {
                    data: [
                      realTimeStats.callsWaiting,
                      Math.max(
                        queueSettings.maxCallers - realTimeStats.callsWaiting,
                        0
                      ),
                    ],
                    showNumber: true,
                  }
                : undefined
            }
          />
        ) : null}
        {widgetsVisibilitySettings[WidgetType.averageWaitingTime] ? (
          <BasicWidget
            label="Average WT"
            className={styles.widget}
            value={realTimeStats.avgWaitingTime}
            limit={queueSettings?.slaAvgWaitingTime}
            highlightLimit={true}
            format="time"
          />
        ) : null}
        {widgetsVisibilitySettings[WidgetType.averageWaitingTimeToday] ? (
          <BasicWidget
            label="Average WT today"
            className={styles.widget}
            format="time"
            value={avgWtToday}
          />
        ) : null}
        {widgetsVisibilitySettings[WidgetType.maxWaitingTime] ? (
          <BasicWidget
            label="Max WT"
            className={styles.widget}
            value={maxWtToday}
            highlightLimit={true}
            limit={queueSettings?.slaMaxWaitingTime}
            format="time"
          />
        ) : null}
        {widgetsVisibilitySettings[WidgetType.callsToday] ? (
          <BasicWidget
            label="Calls today"
            className={styles.widget}
            value={todayStats ? todayStats.callsAnswered : 0}
            limit={todayStats ? todayStats.callsCount : 0}
          />
        ) : null}
      </div>
    </section>
  );
};

export default QueueStats;
