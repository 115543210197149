import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectMessages, updateMessages } from "src/store/settings";
import styles from "./SettingsMessagesForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { debouncedFn } from "src/utils";

const getMessagesMap = (messages: string[]) => {
  const messagesMap: { [key: string]: string } = {};
  messages.forEach((message, idx) => {
    messagesMap[`message-${idx}`] = message;
  });
  return messagesMap;
};

const SettingsMessagesForm = () => {
  const dispatch = useDispatch();
  const messages = useSelector(selectMessages);
  const [formMessages, setFormMessages] = useState(messages);

  const defaultFormSettings = useRef<{ [key: string]: string }>();
  if (!defaultFormSettings.current) {
    defaultFormSettings.current = getMessagesMap(messages);
  }

  const { register, handleSubmit, reset } = useForm({
    defaultValues: defaultFormSettings.current,
  });
  const formMessagesRef = useRef(formMessages);
  useEffect(() => {
    formMessagesRef.current = formMessages;
    const save = async () => {
      const updatedMessages = formMessages.filter((message) => !!message);
      if (JSON.stringify(messages) === JSON.stringify(updatedMessages)) {
        return;
      }
      await dispatch(updateMessages(updatedMessages));
    };
    // Prevent to much save actions when user typing in the form
    return debouncedFn(save, 1000, `SettingsMessagesForm:save`);
  }, [formMessages, messages, dispatch]);

  // Make sure all changes saved before un-mount
  useEffect(() => {
    dispatch(updateMessages(formMessagesRef.current));
  }, [dispatch]);

  const handleChange = handleSubmit(async (formData) => {
    debouncedFn(
      () => {
        const updatedMessages: string[] = [];
        Object.keys(formData).forEach((key) => {
          updatedMessages[parseInt(key.split("-")[1])] = formData[key];
        });
        setFormMessages(updatedMessages);
      },
      100,
      "SettingsMessagesForm:handleChange"
    );
  });

  const handleRemove = (idx: number) => {
    const updatedMessages = [...formMessages];
    updatedMessages.splice(idx, 1);
    reset(getMessagesMap(updatedMessages));
    setFormMessages(updatedMessages);
  };

  const handleAdd = () => {
    setFormMessages([...formMessages, ""]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        Messages are shown to the agents. When more than one message is added,
        they will be rotating.
      </div>
      <form className={styles.form} onChange={handleChange}>
        {(formMessages.length ? formMessages : [""]).map((_, idx) => {
          return (
            <div key={idx} className={styles.control}>
              <input
                name={`message-${idx}`}
                type="text"
                ref={register({ required: false })}
                placeholder={`Message ${idx + 1}`}
              />
              {formMessages.length > 1 ? (
                <button
                  type="button"
                  onClick={() => handleRemove(idx)}
                  className={styles.removeBtn}
                >
                  Delete
                </button>
              ) : null}
            </div>
          );
        })}
      </form>
      <div className={styles.addBtn} onClick={handleAdd}>
        <FontAwesomeIcon className={styles.addBtnIcon} icon={faPlus} />
        Add another message
      </div>
    </div>
  );
};

export default SettingsMessagesForm;
