import React from "react";
import styles from "./MultiSelect.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";

export type SelectItem = {
  text: string;
  value: string;
};

type SelectMenuProps = {
  items: SelectItem[];
  value: SelectItem["value"][];
  onSelect: (value: SelectItem["value"]) => void;
};

const Select: React.FC<SelectMenuProps> = ({ items, value, onSelect }) => {
  return (
    <div className={styles.select}>
      {items.map((item, idx) => {
        const isActive = value.includes(item.value);
        return (
          <div
            key={idx}
            onClick={() => onSelect(item.value)}
            className={`${styles.item} ${
              isActive ? styles["item--active"] : ""
            }`}
          >
            <div className={styles.itemText}>{item.text}</div>
            <div className={styles.itemIcon}>
              {isActive ? <FontAwesomeIcon icon={faCheck} /> : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Select;
