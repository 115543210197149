import React from "react";
import styles from "./Button.module.css";

export type ButtonProps = {
  color?: "primary" | "grey";
};

const Button: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps
> = (props) => {
  let className = styles.button;
  if (props.className) {
    className = `${className} ${props.className}`;
  }
  return <button {...{ ...props, className }}>{props.children}</button>;
};

export default Button;
