import React from "react";
import styles from "./Select.module.css";

type MenuItem = {
  text: string;
  value: string;
};

type SelectProps = {
  items: MenuItem[];
  value: MenuItem["value"];
  onSelect: (value: MenuItem["value"]) => void;
};

const Select: React.FC<SelectProps> = ({ items, value, onSelect }) => {
  return (
    <div className={styles.select}>
      {items.map((item, idx) => (
        <div
          key={idx}
          onClick={() => onSelect(item.value)}
          className={`${styles.item} ${
            value === item.value ? styles["item--active"] : ""
          }`}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

export default Select;
