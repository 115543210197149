import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout, selectAuthUserName } from "src/store/auth";
import Dropdown from "../Dropdown/Dropdown";
import styles from "./Navbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { NavigationPage, setPage } from "src/store/navigation";
import logoImg from "src/assets/panel-logo-light.png";

const Navbar: React.FC = () => {
  const userName = useSelector(selectAuthUserName);
  const dispatch = useDispatch();
  const handleOpenSettings = () => {
    dispatch(setPage(NavigationPage.settings));
  };
  const handleOpenLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.navbarLogo}>
          <img alt="Compass Panel Logo" src={logoImg} />
        </div>
        <Dropdown
          items={[
            { title: "Settings", onClick: handleOpenSettings },
            { title: "Logout", onClick: handleOpenLogout },
          ]}
        >
          <button className={styles.userBtn}>
            <span>{userName}</span> <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </Dropdown>
      </div>
      <div className={styles.navbarPlaceholder}></div>
    </>
  );
};

export default Navbar;
