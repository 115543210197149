import { configureStore, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import logger from "redux-logger";
import rootReducer, { RootState } from "./rootReducer";

const middleware = getDefaultMiddleware({
  thunk: true,
  serializableCheck: {
    ignoredActions: [`data/addRealtimeCall`],
    ignoredActionPaths: ["meta.arg.connection"],
  },
}).concat(logger);

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
