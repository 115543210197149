import { Queue } from "compass.js";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  QueueSettings,
  selectQueues,
  selectQueuesSettings,
  updateQueuesSettings,
} from "src/store/settings";
import { debouncedFn, settingsQueuesListsEquality } from "src/utils";
import styles from "./SettingsValuesForm.module.css";
import stable from "stable";
import { isQueueVisible } from "src/utils/queue";

const validatePattern = /^[0-9]*$/;

enum ControlName {
  maxCallers = "maxCallers",
  slaMaxCallers = "slaMaxCallers",
  slaMaxWaitingTime = "slaMaxWaitingTime",
  slaAvgWaitingTime = "slaAvgWaitingTime",
}

const controls = [
  { name: ControlName.maxCallers, label: "Max callers in queue" },
  { name: ControlName.slaMaxCallers, label: "SLA max callers in queue" },
  { name: ControlName.slaMaxWaitingTime, label: "SLA max WT (sec)" },
  { name: ControlName.slaAvgWaitingTime, label: "SLA average WT (sec)" },
];

const getControlName = (name: ControlName, queueId: Queue["id"]) => {
  return `${name}--${queueId}`;
};

const SettingsValuesForm: React.FC = () => {
  const dispatch = useDispatch();
  const queueSettings = useSelector(selectQueuesSettings);
  const [formQueueSettings, setFormQueueSettings] = useState(queueSettings);
  let queues = useSelector(selectQueues, settingsQueuesListsEquality);

  queues = stable(queues, (a, b) => {
    if (
      isQueueVisible(a.id, queueSettings) &&
      !isQueueVisible(b.id, queueSettings)
    ) {
      return -1;
    } else if (
      !isQueueVisible(a.id, queueSettings) &&
      isQueueVisible(b.id, queueSettings)
    ) {
      return 1;
    }
    return 0;
  });
  const defaultFormSettings: { [key: string]: string } = {};
  Object.keys(queueSettings).forEach((queueId) => {
    controls.forEach(({ name }) => {
      const value = queueSettings[queueId][name];
      if (value !== undefined && value !== null) {
        defaultFormSettings[getControlName(name, queueId)] = value.toString();
      }
    });
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: defaultFormSettings,
  });
  const formQueueSettingsRef = useRef(formQueueSettings);
  useEffect(() => {
    formQueueSettingsRef.current = formQueueSettings;
    const save = async () => {
      if (JSON.stringify(queueSettings) === JSON.stringify(formQueueSettings)) {
        return;
      }
      await dispatch(updateQueuesSettings(formQueueSettings));
    };
    // Prevent to much save actions when user typing in the form
    return debouncedFn(save, 1000, `SettingsValuesForm:save`);
  }, [formQueueSettings, queueSettings, dispatch]);

  // Make sure all changes saved before un-mount
  useEffect(
    () => () => {
      dispatch(updateQueuesSettings(formQueueSettingsRef.current));
    },
    [dispatch]
  );

  const handleFormSubmit = handleSubmit(async (formData) => {
    const updatedQueueSettings: { [key: string]: QueueSettings } = {};
    queues.forEach(({ id }) => {
      updatedQueueSettings[id] = {
        ...queueSettings[id],
      };
      controls.forEach(({ name }) => {
        const value = formData[getControlName(name, id)];
        updatedQueueSettings[id][name] = value
          ? parseInt(formData[getControlName(name, id)])
          : undefined;
      });
    });
    setFormQueueSettings(updatedQueueSettings);
  });

  const handleChange = () => {
    debouncedFn(
      () => handleFormSubmit(),
      100,
      "SettingsValuesForm:handleChange"
    );
  };

  const $formEl = useRef<HTMLFormElement | null>(null);
  return (
    <form
      onChange={handleChange}
      onSubmit={handleFormSubmit}
      ref={$formEl}
      className={styles.valuesForm}
    >
      {queues.map(({ id, name }) => (
        <div key={id} className={styles.queue}>
          <div className={styles.title}>
            {name}
            <div
              className={`${styles.badge} ${
                isQueueVisible(id, queueSettings)
                  ? styles[`badge--visible`]
                  : ""
              }`}
            >
              {isQueueVisible(id, queueSettings) ? "visible" : "hidden"}
            </div>
          </div>
          <div className={styles.form}>
            {controls.map(({ name, label }) => {
              const controlName = getControlName(name, id);
              return (
                <div
                  key={controlName}
                  className={`${styles.formControl} ${
                    errors[controlName] ? styles["formControl--has-error"] : ""
                  }`}
                >
                  <div className={styles.formLabel}>{label}</div>
                  <div className={styles.formInput}>
                    <input
                      name={controlName}
                      type="text"
                      ref={register({
                        validate: (value) => validatePattern.test(value),
                      })}
                      placeholder="0"
                    />
                  </div>
                  {errors[controlName]?.type === "validate" ? (
                    <div className={styles.formError}>
                      The value should be a number
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </form>
  );
};

export default SettingsValuesForm;
