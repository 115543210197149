import { RestApi } from "compass.js";
import { ErrorType, wrapApiError } from "./errorHandler";

export type CompassCompany = {
  contact: string;
  domain: string;
  entityId: number;
  musicOnHold: number;
  name: string;
  self: string;
  shortname: string;
  sipDomain: string;
  theLanguage: string;
  theType: string;
  xmppDomain: string;
};

export type CompassResource = {
  self: string;
  theType: string;
  resourceId: number;
  owner: number;
  name: string;
};

const DEFAULT_SERVER = "apollo.compass-env.com";

export const getConnectInfo = async (
  fullUsername: string,
  password: string
): Promise<{ server: string; jid: string }> => {
  // for development: username can be username?mystack.compass.com
  // to use a non-apollo stack
  const [username, connectDomain] = fullUsername.split("?");
  // username can be regular (username) or with company domain (username@companydomain.com)
  const [localuser] = username.split("@");

  // NOTE: If user did not specify a domain, assume it's on the Apollo stack.
  const stackBasedom = connectDomain || DEFAULT_SERVER;

  // Resolve the correct whitelabel basedom to connect to
  let company: CompassCompany;
  try {
    const restApi = new RestApi(stackBasedom, username, password);
    company = await wrapApiError(restApi.get("company"));
  } catch (error) {
    if (
      connectDomain &&
      error &&
      error.type === ErrorType.api &&
      error.error &&
      error.error.readyState === 0
    ) {
      error.type = ErrorType.loginBasedomConnection;
    }
    throw error;
  }
  // retrieve whitelabel domain by splitting the company's sipDomain
  const whitelabelBasedom = company.sipDomain.split(".").slice(1).join(".");

  return {
    server: whitelabelBasedom,
    // for regular users: username@uc.whitelabelreseller.com
    // for company-domain users: username@companydomain.com
    jid: `${localuser}@${company.xmppDomain}`,
  };
};
