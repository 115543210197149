import React, { useEffect, useRef, useState } from "react";
import Navbar from "src/components/Navbar/Navbar";
import styles from "./Base.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";

const Base: React.FC<{ showScrollTopBtn?: boolean }> = ({
  children,
  showScrollTopBtn,
}) => {
  const [scrollTopBtnVisible, setScrollTopBtnVisible] = useState<boolean>(
    false
  );
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!showScrollTopBtn) {
      if (scrollTopBtnVisible) {
        setScrollTopBtnVisible(false);
      }
      return;
    }
    const node = contentRef.current;
    if (!node) {
      return;
    }
    const scrollCallBack = (e: Event) => {
      let currentScrollTopBtnVisible = node.scrollTop > 50;
      if (scrollTopBtnVisible !== currentScrollTopBtnVisible) {
        setScrollTopBtnVisible(currentScrollTopBtnVisible);
      }
    };
    node.addEventListener("scroll", scrollCallBack);
    return () => {
      node.removeEventListener("scroll", scrollCallBack);
    };
  }, [contentRef, scrollTopBtnVisible, showScrollTopBtn]);
  const scrollTop = () => {
    if (!contentRef.current) {
      return;
    }
    contentRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className={styles.base}>
      <Navbar />
      <div className={styles.content} ref={contentRef}>
        {children}
      </div>
      {scrollTopBtnVisible ? (
        <div className={styles.scrollTopBtn} onClick={scrollTop}>
          <FontAwesomeIcon icon={faChevronUp} />
        </div>
      ) : null}
    </section>
  );
};

export default Base;
