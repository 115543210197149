import React from "react";
import styles from "./Loading.module.css";
import { Loader } from "src/components/Loader/Loader";

const Loading: React.FC = () => {
  return (
    <div className={styles.loading}>
      <Loader size={"large"} color={"primary"} />
    </div>
  );
};

export default Loading;
