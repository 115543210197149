import React, { useState } from "react";
import styles from "./SettingsViewForm.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  QueueSettings,
  selectQueues,
  selectQueuesSettings,
  selectWidgetsVisibilitySettings,
  updateQueuesSettings,
  updateWidgetsVisibilitySettings,
  WidgetType,
} from "src/store/settings";
import { Queue } from "compass.js";
import MultiSelect from "../MultiSelect/MultiSelect";
import { settingsQueuesListsEquality } from "src/utils";
import { isQueueVisible } from "src/utils/queue";

const SettingsViewForm: React.FC = () => {
  const dispatch = useDispatch();
  const queues = useSelector(selectQueues, settingsQueuesListsEquality);
  const queuesSettings = useSelector(selectQueuesSettings);
  const widgetsVisibilitySettings = useSelector(
    selectWidgetsVisibilitySettings
  );
  const [queueVisibilityState, setQueueVisibilityState] = useState<
    Queue["id"][]
  >(
    queues
      .filter((queue) => isQueueVisible(queue.id, queuesSettings))
      .map((queue) => queue.id)
  );
  const [widgetsVisibilityState, setWidgetsVisibilityState] = useState<
    string[]
  >(
    Object.keys(widgetsVisibilitySettings).filter(
      (widgetType) => widgetsVisibilitySettings[widgetType as WidgetType]
    )
  );

  const handleQueuesVisibilitySelect = (value: string) => {
    const updatedQueueVisibilityState = queueVisibilityState.includes(value)
      ? queueVisibilityState.filter((item) => item !== value)
      : [...queueVisibilityState, value];
    setQueueVisibilityState(updatedQueueVisibilityState);
    const updatedQueuesSettings: { [key: string]: QueueSettings } = {};
    queues.forEach((queue) => {
      updatedQueuesSettings[queue.id] = {
        ...queuesSettings[queue.id],
        visible: updatedQueueVisibilityState.includes(queue.id),
      };
    });
    dispatch(updateQueuesSettings(updatedQueuesSettings));
  };

  const handleWidgetsVisibilitySelect = (value: string) => {
    const updatedWidgetsVisibilityState = widgetsVisibilityState.includes(value)
      ? widgetsVisibilityState.filter((item) => item !== value)
      : [...widgetsVisibilityState, value];
    const updatedWidgetsVisibilitySettings = {
      ...widgetsVisibilitySettings,
      [value as WidgetType]: updatedWidgetsVisibilityState.includes(value),
    };
    setWidgetsVisibilityState(updatedWidgetsVisibilityState);
    dispatch(updateWidgetsVisibilitySettings(updatedWidgetsVisibilitySettings));
  };

  return (
    <div className={styles.form}>
      <div className={styles.section}>
        <div className={styles.title}>Queues</div>
        <div className={styles.description}>
          Choose which queues to show or hide on dashboard.
        </div>
        <MultiSelect
          items={queues.map((queue) => ({ text: queue.name, value: queue.id }))}
          value={queueVisibilityState}
          onSelect={(value) => handleQueuesVisibilitySelect(value)}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Widgets</div>
        <div className={styles.description}>
          Choose which widgets to show for each queue
        </div>
        <MultiSelect
          items={[
            {
              text: "Number of agents",
              value: WidgetType.agentsNumber,
            },
            {
              text: "Callers waiting",
              value: WidgetType.callersWaiting,
            },
            {
              text: "Average waiting time",
              value: WidgetType.averageWaitingTime,
            },
            {
              text: "Average WT today",
              value: WidgetType.averageWaitingTimeToday,
            },
            {
              text: "Maximum waiting time",
              value: WidgetType.maxWaitingTime,
            },
            {
              text: "Calls today",
              value: WidgetType.callsToday,
            },
          ]}
          value={widgetsVisibilityState}
          onSelect={(value) => handleWidgetsVisibilitySelect(value)}
        />
      </div>
    </div>
  );
};

export default SettingsViewForm;
