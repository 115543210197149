import {
  Call,
  CallPointState,
  CallPointType,
  Queue,
  ReceiveCalls,
  UserCallPoint,
} from "compass.js";
import { QueueSettings } from "src/store/settings";

export type QueueDetails = {
  id: Queue["id"];
  name: Queue["name"];
};

export type QueueDetailsStatistics = {
  agentsAvailable: number;
  agentsInCall: number;
  agentsAway: number;
  agentsBusy: number;
  agentsTotal: number;
  callsWaiting: number;
  avgWaitingTime: number;
  waitDurations: number[];
};

const getQueueWaitDurations = (queue: Queue): number[] => {
  return Object.values(queue.getCalls()).map(
    (call: Call) => +new Date() / 1000 - call.destination.timeCreated
  );
};

/**
 * Get avarage waiting time for queue in seconds
 * @param queue Queue
 */
const getQueueAvgWaitingTime = (queue: Queue) => {
  const waitDurations = getQueueWaitDurations(queue);
  if (!waitDurations.length) {
    return 0;
  }
  return Math.round(
    waitDurations.reduce((a, b) => a + b) / waitDurations.length
  );
};

export const getQueueStatistics = (queue: Queue): QueueDetailsStatistics => {
  let agentsAvailable = 0;
  let agentsInCall = 0;
  let agentsBusy = 0;
  let agentsAway = 0;
  let agentsTotal = 0;
  queue.getUsers().forEach((user) => {
    if (!user.loggedIn) {
      return;
    }
    agentsTotal += 1;
    const activeCall = user.getCalls().find((item) => {
      let userCallPoint: UserCallPoint | undefined;
      if (
        item.source.type === CallPointType.user &&
        (item.source as UserCallPoint).userId === user.id
      ) {
        userCallPoint = item.source as UserCallPoint;
      } else if (
        item.destination.type === CallPointType.user &&
        (item.destination as UserCallPoint).userId === user.id
      ) {
        userCallPoint = item.destination as UserCallPoint;
      }
      if (!userCallPoint) {
        return false;
      }
      return [CallPointState.answered, CallPointState.inactive].includes(
        userCallPoint.state
      );
    });
    if (activeCall) {
      agentsInCall += 1;
    } else if (user.status.receiveCalls === ReceiveCalls.none) {
      agentsAway += 1;
    } else if (user.status.receiveCalls === ReceiveCalls.onlyDirect) {
      agentsBusy += 1;
    } else {
      agentsAvailable += 1;
    }
  });
  return {
    agentsAvailable,
    agentsInCall,
    agentsAway,
    agentsBusy,
    agentsTotal,
    callsWaiting: queue.getCalls().length,
    avgWaitingTime: getQueueAvgWaitingTime(queue),
    waitDurations: getQueueWaitDurations(queue),
  };
};

export const getQueueDetails = (queue: Queue): QueueDetails => {
  return {
    id: queue.id,
    name: queue.name,
  };
};

export const isQueueVisible = (
  queueId: Queue["id"],
  queuesSettings: {
    [key: string]: QueueSettings;
  }
) => {
  if (queuesSettings[queueId]) {
    return queuesSettings[queueId].visible;
  }
  // All queues is visible by default if user didn't change settings yet
  return !Object.keys(queuesSettings).length;
};
