const SECOND = 1;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

/**
 * Convert duration in seconds to human readable string
 * @param duration Duration in seconds
 */
export const humanizeDuration = (duration: number) => {
  const hoursRest: number = duration % HOUR;
  const minutesRest: number = hoursRest % MINUTE;
  const hours = String(Math.floor(duration / HOUR) || "");
  const minutes = String(Math.floor(hoursRest / MINUTE));
  const seconds = String(Math.floor(minutesRest / SECOND));
  return `${hours.length ? hours + ":" : ""}${
    minutes.length === 1 ? "0" + minutes : minutes
  }:${seconds.length === 1 ? "0" + seconds : seconds}`;
};

export const getDateStr = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  return `${year}-${month.length === 1 ? `0${month}` : month}-${
    day.length === 1 ? `0${day}` : day
  }`;
};
