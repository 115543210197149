import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNotifications,
  NotificationParams,
  dismiss,
  setupNotifications,
} from "src/store/notifications";
import styles from "./Notifications.module.css";

const Notification: React.FC<{ id: NotificationParams["uid"] }> = ({ id }) => {
  const notifications = useSelector(selectNotifications);
  const notification = notifications.find(
    (notification) => notification.uid === id
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!notification?.autoDismiss) {
      return;
    }
    let timeout: NodeJS.Timer | null = setTimeout(() => {
      dispatch(dismiss(id));
      timeout = null;
    }, notification.autoDismiss);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!notification) {
    return null;
  }
  let icon = faCheck;
  if (notification.level === "error") {
    icon = faExclamationCircle;
  }
  return (
    <div
      className={`${styles.notification} ${
        styles[`notification--${notification.level}`]
      }`}
    >
      <div className={styles.notificationContent}>
        <div className={styles.notificationTop}>
          <div className={styles.notificationIcon}>
            <FontAwesomeIcon icon={icon} />
          </div>
          <div className={styles.notificationTitle}>{notification.title}</div>
          {notification.dismissible ? (
            <div
              onClick={() => dispatch(dismiss(id))}
              className={styles.notificationDismissBtn}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          ) : null}
        </div>
        {notification.message ? (
          <div className={styles.notificationMessage}>
            {notification.message}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Notifications: React.FC = () => {
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setupNotifications());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={styles.notifications}>
      {notifications.map(({ uid }) => (
        <Notification key={uid} id={uid} />
      ))}
    </div>
  );
};

export default Notifications;
