import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export enum NavigationPage {
  dashboard = "dashboard",
  settings = "settings",
}

type NavigationState = {
  readonly page: NavigationPage;
};

const initialState: NavigationState = {
  page: NavigationPage.dashboard,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setPage(state, { payload: page }: { payload: NavigationPage }) {
      state.page = page;
    },
    reset() {
      return initialState;
    },
  },
});

export const { setPage, reset } = navigationSlice.actions;

export const selectPage = ({ navigation: { page } }: RootState) => page;

export default navigationSlice.reducer;
