import React, { useEffect, useRef, useState } from "react";
import styles from "./RotatingMessage.module.css";

const RotatingMessage: React.FC<{ messages: string[] }> = ({ messages }) => {
  const [visibleMessage, setVisibleMessage] = useState<{
    idx: number;
    message: string;
  }>();
  const updateInterval = useRef<NodeJS.Timer | null>(null);
  useEffect(() => {
    if (updateInterval.current) {
      clearInterval(updateInterval.current);
      updateInterval.current = null;
    }
    if (!visibleMessage) {
      setVisibleMessage({ idx: 0, message: messages[0] });
    }
    updateInterval.current = setInterval(() => {
      if (!messages.length) {
        return setVisibleMessage(undefined);
      }
      let idx = 0;
      const currentIdx = visibleMessage?.idx;
      if (
        visibleMessage &&
        currentIdx !== undefined &&
        currentIdx + 1 < messages.length
      ) {
        idx = currentIdx + 1;
      }
      setVisibleMessage({ idx, message: messages[idx] });
    }, 8000);
    return () => {
      if (updateInterval.current) {
        clearInterval(updateInterval.current);
        updateInterval.current = null;
      }
    };
  }, [visibleMessage, messages]);
  if (!visibleMessage) {
    return null;
  }
  return <div className={styles.rotatingMessage}>{visibleMessage.message}</div>;
};

export default RotatingMessage;
