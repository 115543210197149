import { notificationsQueue$ } from "src/store/notifications";
import { reactIsInDevMode } from "./index";

export enum ErrorType {
  api = "api",
  runtime = "runtime",
  loginBasedomConnection = "loginBasedomConnection",
}

export interface Error {
  type: ErrorType;
  error?: any;
}

export const handleError = (error?: Error) => {
  if (reactIsInDevMode()) {
    console.error(error);
  }
  notificationsQueue$.next({
    title: "Error",
    message: getErrorMessage(),
    level: "error",
    autoDismiss: 3000,
    dismissible: true,
  });
};

export const getErrorMessage = (error?: Error) => {
  let message =
    "Something has gone wrong, please try again later or contact support";
  if (error) {
    switch (error.type) {
      case ErrorType.loginBasedomConnection:
        message = "Can't connect to the server given in credentials.";
        break;
      case ErrorType.api:
        if (!window.navigator.onLine) {
          message = "There is no connection. Try reconnecting.";
        } else if (
          error.error &&
          error.error.responseJSON &&
          error.error.responseJSON.message
        ) {
          message = error.error.responseJSON.message;
        }
        break;
    }
  }
  return message;
};

/**
 * Automatically converts API error response to IError
 * @param p Promise
 */
export const wrapApiError = <T>(p: Promise<T>) => {
  return p.catch((error) => {
    // eslint-disable-next-line no-throw-literal
    throw {
      type: ErrorType.api,
      error,
    };
  });
};

/**
 * Convert runtime error to IError
 * @param error Exception
 */
export const createRuntimeError = (error?: any): Error => {
  return {
    type: ErrorType.runtime,
    error,
  };
};
