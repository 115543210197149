import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { Subject } from "rxjs";
import { RootState } from "./rootReducer";

export type NotificationParams = {
  title: string;
  message?: string;
  level: "success" | "error";
  uid: string;
  autoDismiss?: number;
  dismissible?: boolean;
};

type NavigationState = {
  readonly items: NotificationParams[];
};

const initialState: NavigationState = {
  items: [],
};

export const notificationsQueue$: Subject<
  | NotificationParams
  | Pick<NotificationParams, "title" | "message" | "autoDismiss" | "level">
> = new Subject();

export const setupNotifications = createAsyncThunk(
  "notifications/setup",
  (_, { dispatch }) => {
    notificationsQueue$.subscribe((notification) => {
      dispatch(notificationsSlice.actions.show(notification));
    });
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    show(
      state,
      {
        payload: notification,
      }: {
        payload:
          | NotificationParams
          | Pick<
              NotificationParams,
              "title" | "message" | "autoDismiss" | "level"
            >;
      }
    ) {
      state.items = [
        {
          uid: "uid" in notification ? notification.uid : nanoid(),
          ...notification,
        },
        ...state.items,
      ];
    },
    dismiss(
      state,
      {
        payload: uid,
      }: {
        payload: string;
      }
    ) {
      state.items = state.items.filter(
        (notification) => notification.uid !== uid
      );
    },
    dismissAll(state) {
      state.items = [];
    },
  },
});

export const selectNotifications = ({ notifications: { items } }: RootState) =>
  items;

export const { show, dismiss, dismissAll } = notificationsSlice.actions;

export default notificationsSlice.reducer;
