import authReducer from "./auth";
import settingsReducer from "./settings";
import navigationReducer from "./navigation";
import dataReducer from "./data";
import notificationsReducer from "./notifications";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  navigation: navigationReducer,
  data: dataReducer,
  notifications: notificationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
