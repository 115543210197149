import React, { useEffect, useRef } from "react";
import Chart from "chart.js";
import styles from "./DoughnutChart.module.css";

export type DoughnutChartProps = {
  data: number[];
  showNumber?: boolean;
  color?: "danger" | "warning";
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  data,
  color,
  showNumber,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | null>(null);
  useEffect(() => {
    let colorHex = "#ffffff";
    switch (color) {
      case "warning":
        colorHex = "#fcbc26";
        break;
      case "danger":
        colorHex = "#eb5151";
        break;
    }
    if (!chartRef.current) {
      if (!canvasRef.current) {
        return;
      }
      const ctx = canvasRef.current.getContext("2d");
      if (!ctx) {
        return;
      }
      chartRef.current = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              borderWidth: 0,
              data,
              backgroundColor: [colorHex, "#a6b2cc30"],
            },
          ],
        },
        options: {
          cutoutPercentage: 70,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
        },
      });
    } else {
      chartRef.current.data.datasets![0].data = data;
      chartRef.current.data.datasets![0].backgroundColor = [
        colorHex,
        "#a6b2cc30",
      ];
      chartRef.current.update();
    }
  }, [canvasRef, chartRef, data, color]);
  return (
    <div className={styles.wrap}>
      {showNumber ? (
        <div className={styles.chartNumber}>
          {data.reduce((a, b) => a + b, 0)}
        </div>
      ) : null}
      <canvas
        className={styles.chart}
        style={{ transform: `rotate(180deg)`, width: "100%", height: "100%" }}
        ref={canvasRef}
      />
    </div>
  );
};

export default DoughnutChart;
