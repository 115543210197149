import React, { useEffect, useState } from "react";
import styles from "./SettingsNavbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft } from "@fortawesome/pro-solid-svg-icons/faLongArrowLeft";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { NavigationPage, setPage } from "src/store/navigation";
import { selectSettingsUpdateInProgress } from "src/store/settings";
import { usePrevious } from "src/utils";
import { show as showNotification } from "src/store/notifications";

const SettingsNavbar: React.FC<{ doneBtnDisabled?: boolean }> = ({
  doneBtnDisabled,
}) => {
  const dispatch = useDispatch();
  const settingsUpdateInProgress = useSelector(selectSettingsUpdateInProgress);
  const previousSettingsInProgress = usePrevious(settingsUpdateInProgress);
  const [showSavedLabel, setShowSavedLabel] = useState<boolean>(false);
  useEffect(() => {
    if (settingsUpdateInProgress || !previousSettingsInProgress) {
      return;
    }
    setShowSavedLabel(true);
    const showSavedTimeout = setTimeout(() => {
      setShowSavedLabel(false);
    }, 2000);
    return () => {
      if (showSavedTimeout) {
        clearTimeout(showSavedTimeout);
      }
    };
  }, [settingsUpdateInProgress, previousSettingsInProgress]);
  return (
    <div className={styles.navbar}>
      <div className={styles.title}>
        <div
          className={styles.titleIcon}
          onClick={() => dispatch(setPage(NavigationPage.dashboard))}
        >
          <FontAwesomeIcon icon={faLongArrowLeft} />
        </div>
        Panel /<span> Settings</span>
      </div>
      <div className={styles.left}>
        <div className={styles.saveLabel}>
          {settingsUpdateInProgress
            ? "Saving"
            : showSavedLabel && !doneBtnDisabled
            ? "Changes saved"
            : null}
        </div>
        <Button
          disabled={doneBtnDisabled}
          onClick={() => {
            dispatch(
              showNotification({
                title: "Changes saved",
                level: "success",
                autoDismiss: 3000,
                dismissible: true,
              })
            );
            dispatch(setPage(NavigationPage.dashboard));
          }}
          color={"grey"}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default SettingsNavbar;
