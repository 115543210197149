import React from "react";
import styles from "./Card.module.css";

const Card: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  let className = styles.card;
  if (props.className) {
    className = `${className} ${props.className}`;
  }
  return <div {...{ ...props, className }}>{props.children}</div>;
};

export default Card;
