import React, { useEffect, useRef, useState } from "react";
import styles from "./Dropdown.module.css";

type DropdownProps = {
  items: {
    title: string;
    onClick: () => void;
  }[];
};

const Dropdown: React.FC<DropdownProps> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const $dropdownElement = useRef<HTMLDivElement>(null);
  const toggle = () => setIsActive(!isActive);
  useEffect(() => {
    if (!isActive) {
      return;
    }
    const documentClickHandler = (e: MouseEvent) => {
      if (!isActive || $dropdownElement.current?.contains(e.target as Node)) {
        return;
      }
      setIsActive(false);
    };
    document.addEventListener("click", documentClickHandler);
    return () => {
      document.removeEventListener("click", documentClickHandler);
    };
  }, [isActive]);
  return (
    <div
      ref={$dropdownElement}
      onClick={toggle}
      className={`${styles.dropdown} ${
        isActive ? styles["dropdown--active"] : ""
      }`}
    >
      {props.children}
      <div className={styles.content}>
        {props.items.map((item, idx) => (
          <div key={idx} className={styles.item} onClick={item.onClick}>
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
